<script setup>
const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: { type: Boolean, default: false },
  menuClass: { type: String, default: '' },
})

const route = useRoute()
const $visible = ref(props.modelValue)
const menuRef = ref()

onClickOutside(menuRef, () => {
  $visible.value = false
  emits('update:modelValue', false)
})

// 親コンポーネントの変更に合わせて visible を変更
watch(() => props.modelValue, (v) => $visible.value = v);
watch(() => route, aft => emits('update:modelValue', false), {deep: true})
</script>

<template>
  <div class="menu-wrapper">
    <slot
      name="activator"
      :visible="$visible"
    ></slot>

    <div
      ref="menuRef"
      class="menu"
      :class="[menuClass, { 'menu-hide': !$visible }]"
    >
      <slot></slot>
    </div>
  </div>
</template>